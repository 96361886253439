/* eslint-disable no-unused-vars */
// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import api from "@src/api/config"
import fileDownload from "js-file-download"
import {
  Shield,
  Success,
  Home as HomeIcon,
  ToastSuccess,
  DownloadFileIcon
} from "../../../assets/images/icons"
import { showModalSuccess } from "../../../redux/modalTypeSuccess"
import { Button, Label } from "reactstrap"
import { showModalError } from "../../../redux/modalTypeError"
import {
  showModalFullCustom,
  hideModalFullCustom,
  clearUrl
} from "../../../redux/modalTypeFullCustom"
import { showModalCustom, hideModalCustom, setLoading } from "../../../redux/modalTypeCustom"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import SuccessToast from "../../../components/common/toast/SuccessToast"
import _ from "lodash"
import ErrorToast from "../../../components/common/toast/ErrorToast"
import { userRoles } from "../../../utility/constants"
import { array } from "prop-types"
import { clearWizard } from "."
/* import { useSelector } from "react-redux" */
/* const { userData } = useSelector((state) => state.auth.userData) */

function getToRedirect(role, id) {
  switch (role) {
    case userRoles.CLIENT:
      return `/settings`
    case userRoles.SELLER:
      return `/seller/clients/show/${id}`
    case userRoles.ADMIN:
      return `/users/clients/show/${id}`
  }
}

export const saveCertificate = createAsyncThunk(
  "certs/save",
  async ({ data, nextBillingDate }, { dispatch }) => {
    //try {
    let url = `api/certificates/loads`
    switch (data.producto) {
      case 2:
        url = `api/certificates/electronic_equipment`
        break
      case 3:
        break
      case 4:
        url = `api/certificates/personal_accidents`
        break
      case 6:
        url = `api/certificates/ern`
        break
    }

    const response = await api.post(url, data)
    if (response.data.status === "success") {
      setTimeout(() => {
        dispatch(setLoading(false))
        dispatch(clearWizard())
        dispatch(hideModalCustom())
        const id = response.data.data.client.role_id
        dispatch(
          showModalFullCustom({
            icon: <Success />,
            message: "¡El certificado ha sido creado con éxito!",
            notes: (
              <Label>
                La factura será emitida{" "}
                {nextBillingDate && `el día ${nextBillingDate}`} de acuerdo al{" "}
                <span className={"notice"}>
                  <Link to={`/users/clients/show/${id}`}>
                    perfil de facturación
                  </Link>
                </span>{" "}
                del cliente. Para más información, consulta la sección de
                razones sociales dentro del perfil del cliente.
              </Label>
            ),
            url: `/certs`,
            primaryButton: (
              <Button
                id='showCert'
                color='primary'
                size='medium'
                className='w-100 mt-25 mb-25'
                onClick={() => {
                  dispatch(clearUrl())
                  dispatch(hideModalFullCustom())
                  data.history.push(`/certs/show/${response.data.data.id}`)
                }}
              >
                <Shield width={15} height={15} fill='white' />
                <span className='align-middle ms-25'>Ver certificado</span>
              </Button>
            ),
            secondaryButton: (
              <Button
                color='primary'
                size='medium'
                onClick={() => {
                  dispatch(clearUrl())
                  dispatch(hideModalFullCustom())
                  data.history.push("/certs")
                }}
                className='btn-edit w-100 mt-25 mb-25'
                outline
              >
                <HomeIcon width={15} height={15} fill='#73A2C9' />
                <span className='align-middle ms-25'>Ir al inicio</span>
              </Button>
            )
          })
        )
      }, 3000)
      return {
        data: response.data
      }
    } else {
      dispatch(setLoading(false))
      // console.log('Data Error:', response.data)
      return {
        data: response.data
      }
    }
    // } catch (err) {
    //   dispatch(setLoading(false))
    //   console.log('Error:', err)
    //   return {
    //     data: null
    //   }
    // }
  }
)

export const verifyUniqueFolio = createAsyncThunk(
  "certs/folio",
  async ({clientId, data}) => {
    const response = await api.post(`api/certificates/unique/${clientId}`, data)

    return {
      data: response.data
    }
  }
)

export const loadCertificatesMassive = createAsyncThunk(
  "certs/massive",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.post(`api/certificates/massive`, data)

      return {
        data: response.data
      }
    } catch (error) {
      dispatch(
        showModalError({
          message: "Lo sentimos, hay un error en la información del archivo."
        })
      )

      return rejectWithValue(error.response.data)
    }
  }
)

export const saveCertificatesMassive = createAsyncThunk(
  "certs/massive/save",
  async ({ data, history }, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.post(`api/certificates/massive/save`, data)

      dispatch(setLoading(false))

      if (response.data.status === "success") {
        dispatch(
          showModalFullCustom({
            icon: <Success />,
            message: (
              <center>¡Los certificados han sido creados con éxito!</center>
            ),
            notes: (
              <Label>
                La factura será emitida de acuerdo a tu{" "}
                <span className='notice'>
                  <Link to={`/settings`}>perfil de facturación</Link>
                </span>
                . Para más información, consulta la sección de razones sociales
                dentro de tu perfil.
              </Label>
            ),
            url: `/certs`,
            primaryButton: (
              <Button
                id='showCert'
                color='primary'
                size='medium'
                className='w-100 mt-25 mb-25'
                onClick={() => {
                  dispatch(clearUrl())
                  dispatch(hideModalFullCustom())
                  history.push(`/certs`)
                }}
              >
                <Shield width={15} height={15} fill='white' />

                <span className='align-middle ms-25'>Ver certificados</span>
              </Button>
            ),
            secondaryButton: (
              <Button
                color='primary'
                size='medium'
                onClick={() => {
                  dispatch(clearUrl())
                  dispatch(hideModalFullCustom())
                  history.push("/certs")
                }}
                className='btn-edit w-100 mt-25 mb-25'
                outline
              >
                <HomeIcon width={15} height={15} fill='#73A2C9' />

                <span className='align-middle ms-25'>Ir al inicio</span>
              </Button>
            )
          })
        )
      }

      return {
        data: response.data
      }
    } catch (error) {
      dispatch(
        showModalError({
          message:
            "Lo sentimos, hay un error en la información de uno de los certificados. Favor de corregirla e intentarlo de nuevo."
        })
      )

      return rejectWithValue(error.response.data)
    }
  }
)

export const getCertificatesSuggestionsV2 = createAsyncThunk(
  "certs/suggestionsV2",
  async (params) => {
    const response = await api.get(`api/certificates`, {
      params: { ...params, suggestions: true }
    })
    return {
      params,
      data: response.data
    }
  }
)

export const getPage = createAsyncThunk(
  "certs/getPage",
  async ({ params, page }, { getState }) => {
    const state = getState()
    const pages = state.certificate.pages
    if (!pages.hasOwnProperty(page)) {
      const response = await api.get(`api/certificates`, {
        params: { ...params, page }
      })
      return {
        params: { ...params, page },
        data: response.data
      }
    } else {
      return {
        params: { ...params, page },
        data: pages[page]
      }
    }
  }
)

export const getCertificatesSuggestions = createAsyncThunk(
  "certs/suggestions",
  async (params, { getState }) => {
    console.trace("getCertificatesSuggestions")
    const state = getState()
    const business_line = state.businessLines?.businessLineSelected?.id
    const product = state.products?.productSelected?.value
    params.business_line = business_line
    params.product = product
    const response = await api.get(`api/certificates/all/list`, { params })
    return {
      params,
      data: response.data
    }
  }
)

export const getlastCertificateByUserAndProduct = createAsyncThunk(
  "certs/getlastCertificateByUserAndProduct",
  async (params) => {
    const response = await api.get(`api/certificates/get_latest_certificate/${params}`)
    return {
      params,
      data: response.data
    }
  }
)

/**
 * @typedef {Object} SuggestionsParams
 * @property {'folio' | 'folio_custom' | 'invoice' | 'rep'} search_type - El tipo de búsqueda, los valores aceptados son: folio, folio_custom, invoice, rep
 * @property {string} search - El texto de referencia para las sugerencias
 * @property {Array<string>} [status] - Array de strings con los status sobre los cuáles filtará
 * @property {Array<string>} [range] - Rango de fechas sobre las cuáles se filtrarán los resultados
 * @property {number} product - ID del producto sobre el cuál se realizarán las búsquedas
 */

/**
 * Función para obtener sugerencias.
 * @param {SuggestionsParams} params - Parámetros de búsqueda.
 * @returns {Promise<{ params: SuggestionsParams, data: any }>} - La promesa que resuelve a los datos de las sugerencias.
 */
export const getSuggestions = createAsyncThunk(
  "certs/getSuggestion",
  async (params, { getState }) => {
    const state = getState()
    if (params.search === "") {
      params.product = state.products.productSelected.value
      params.business_line = state.businessLines.businessLineSelected.id
    }
    const response = await api.get("api/certificates/suggestions", { params })
    return {
      params,
      data: response.data
    }
  }
)

export const getScheduledCertificates = createAsyncThunk(
  "certs/scheduled",
  async (clientId) => {
    const response = await api.get(`api/certificates/scheduled/${clientId}`)
    return {
      data: response.data
    }
  }
)

export const getCertificateResume = createAsyncThunk(
  "certs/getResume",
  async (params = {}, { getState }) => {
    const state = getState()
    params.product = state.products?.productSelected?.value
    params.business_line = state.businessLines.businessLineSelected.id
    const response = await api.get("api/certificates/resume", { params })

    return {
      data: response.data.data,
      params
    }
  }
)

/**
 * @description Función para obtener los certificados.
 */
export const getCertificates = createAsyncThunk(
  "certs/get",
  async (params, { dispatch, getState }) => {
    const state = getState()
    const pages = state.certificate.pages
    const page = params.page ?? 1
    const aux = state.certificate.params
    const { page: pageAux, ...current_params } = aux
    const { page: newPageAux, ...new_params } = params
    // filter by product and business line
    params.product = state.products.productSelected.value
    params.business_line = state.businessLines.businessLineSelected.id

    if (
      Object.keys(pages).length === 0 ||
      !pages.hasOwnProperty(page) ||
      !_.isEqual(current_params, new_params)
    ) {
      try {
        const response = await api.get(`api/certificates`, { params })
        
        return {
          params,
          data: response.data
        }
      } catch (error) {
        console.error("💥 | file: certs.js:352 | getCertificates | error:", error)
      }
    } else {
      return {
        params,
        data: pages[page]
      }
    }
  }
)

export const updateCertificate = createAsyncThunk(
  "certs/update",
  async ({ id, data }, { dispatch }) => {
    // try {

    let url = `api/certificates/loads`
    switch (data.producto) {
      case 2:
        url = `api/certificates/electronic_equipment`
        break
      case 3:
        break
      case 4:
        url = `api/certificates/personal_accidents`
        break
      case 6:
        url = `api/certificates/ern`
        break
    }

    const response = await api.put(`${url}/${id}`, data)
    if (response.data.status === "success") {
      setTimeout(() => {
        dispatch(setLoading(false))
        dispatch(hideModalCustom())

        const id = response.data.data.client.role_id
        const status = response.data.data.certificate_status

        dispatch(
          showModalFullCustom({
            icon: <Success />,
            message: "¡El certificado ha sido modificado con éxito!",
            notes: status === "issued" ? (
              <Label>
                La factura será emitida según el{" "}
                <span className={"notice"}>
                  <Link to={`/users/clients/show/${id}`}>
                    perfil de facturación
                  </Link>
                </span>{" "}
                del cliente. Para más información, consulta la sección de
                razones sociales dentro del perfil del cliente.
              </Label>
            ) : null,
            url: `/certs`,
            primaryButton: (
              <Button
                id='showCert'
                color='primary'
                size='medium'
                className='w-100 mt-25 mb-25'
                onClick={() => {
                  dispatch(clearUrl())
                  dispatch(hideModalFullCustom())
                  data.history.push(`/certs/show/${response.data.data.id}`)
                }}
              >
                <Shield width={15} height={15} fill='white' />
                <span className='align-middle ms-25'>Ver certificado</span>
              </Button>
            ),
            secondaryButton: (
              <Button
                color='primary'
                size='medium'
                onClick={() => {
                  dispatch(clearUrl())
                  dispatch(hideModalFullCustom())
                  data.history.push("/certs")
                }}
                className='btn-edit w-100 mt-25 mb-25'
                outline
              >
                <HomeIcon width={15} height={15} fill='#73A2C9' />
                <span className='align-middle ms-25'>Ir al inicio</span>
              </Button>
            )
          })
        )
        dispatch(getCertificates({ page: 1 }))
      }, 3000)
    }
    return {
      data: response.data
    }
    // } catch (err) {
    //   dispatch(setLoading(false))
    //   console.log('Error:', err)
    //   return {
    //     data: null
    //   }
    // }
  }
)

export const saveRenewCert = createAsyncThunk(
  "certs/saveRenewCert",
  async (data) => {
    return data
  }
)

export const removeEnsuredEmployees = createAsyncThunk(
  "certs/removeEnsuredEmployees",
  async (params, { dispatch }) => {
    const response = await api.post(`api/certificates/remove/employees`, params)

    if (response.data.status === "success") {
      const url = `/users/clients/show/${params.id_client}`
      dispatch(showModalSuccess({ message: response.data.message, url }))
      dispatch(hideModalCustom())
    }

    return {
      data: response.data
    }
  }
)

export const getFavoriteSettings = createAsyncThunk(
  "certs/favoriteSettings",
  async (taxDataId, { getState }) => {
    const state = getState()
    const { productSelected } = state.products
    const { businessLineSelected } = state.businessLines
    const params = {
      product: productSelected.value,
      business_line: businessLineSelected?.id
    }
    const response = await api.get(
      `api/certificates/favoritesettings/${taxDataId}`,
      { params }
    )

    return {
      data: response.data
    }
  }
)

export const getRestrictedCountries = createAsyncThunk(
  "certs/restrictedCountries",
  async (params) => {
    const response = await api.get(`api/restricted_countries`)

    return {
      data: response.data
    }
  }
)

export const getCertificateById = createAsyncThunk(
  "certs/getById",
  async (id) => {
    const response = await api.get(`api/certificates/${id}`)
    return {
      data: response.data
    }
  }
)

export const verifyCertificateById = createAsyncThunk(
  "certs/verifyById",
  async (id) => {
    const response = await api.get(`api/certificate/${id}/verify`)
    return {
      data: response.data
    }
  }
)

export const approveCertificate = createAsyncThunk(
  "certs/approve",
  async (id, { dispatch }) => {
    const params = { certificate_status: "issued" }
    const response = await api.put(`api/certificates/${id}`, params)
    dispatch(hideModalCustom())
    if (response.data.status === "success") {
      dispatch(
        showModalSuccess({ message: "El certificado se ha aprobado con éxito" })
      )
    }
    dispatch(getCertificates({ page: 1 }))
    return {
      data: response.data
    }
  }
)

export const cancelCertificate = createAsyncThunk(
  "certs/cancel",
  async ({ payload, history }, { dispatch }) => {
    const params = { certificate_status: "cancelled" }
    const response = await api.delete(`api/certificates/${payload.id}`, params)
    dispatch(hideModalCustom())
    if (response.data.status === "success") {
      dispatch(
        showModalSuccess({
          message: "El certificado se ha cancelado con éxito"
        })
      )

      if (typeof history !== "undefined") {
        history.push("/certs")
      }
    }
    dispatch(getCertificates({ page: 1 }))
    return {
      data: response.data
    }
  }
)

export const downloadCertificate = createAsyncThunk(
  "certs/download",
  ({ id, filename }) => {
    api
      .get(`api/certificate/${id}/download`, { responseType: "blob" })
      .then((res) => {
        fileDownload(res.data, filename)
      })
  }
)

export const previewCertificate = createAsyncThunk(
  "certs/preview",
  async (id) => {
    const response = await api.get(`api/certificate/preview/${id}`)
    return {
      data: response.data
    }
  }
)

export const previewCertificateByEmployee = createAsyncThunk(
  "employee/preview",
  async ({ certificate, params }) => {
    const response = await api.get(
      `api/certificate/employee_preview/${certificate}`,
      { params }
    )
    return {
      data: response.data
    }
  }
)
export const downloadAllCertificates = createAsyncThunk(
  "certs/downloadCerts",
  async (params) => {
    params = { ...params, arrayformat: true }
    api.get(`api/certificates/all/download`, { responseType: "blob", params})
      .then((res) => {
        fileDownload(res.data, "certificados.zip")
        toast(<SuccessToast message={"Descarga exitosa"} />, {
            icon: <ToastSuccess />,
            hideProgressBar: true,
            className: "toast-success",
            autoClose: 5000
        })
      })
  }
)
export const downloadCertificates = createAsyncThunk(
  "certs/downloadCerts",
  async ({ certs, folio_cert = null }) => {
    api
      .get(`api/certificates/download`, {
        responseType: "blob",
        params: {
          certificates: certs
        }
      })
      .then((res) => {
        if (certs.length > 1) {
          fileDownload(res.data, "certificados.zip")
        } else {
          fileDownload(res.data, `Certificado-${folio_cert}.pdf`)
        }
      })

    toast(<SuccessToast message={"Descarga exitosa"} />, {
      icon: <ToastSuccess />,
      hideProgressBar: true,
      className: "toast-success",
      autoClose: 5000
    })
  }
)
/**
 * @description Función para obtener lo id de la consulta actual y descargar todos los certificados consultados sin paginacion .
 * @param {Object} params - Parámetros de la consulta.
 */
export const getAllIdCurrent = createAsyncThunk(
  "certs/get/allid",
  async (params, { dispatch, getState }) => {
    try {
      const state = getState()
      params.product = state.products.productSelected.value
      const response = await api.get(`api/certificates`, { params })
      if (response.status === 200) {
        const action = () => {
          params = { ...params, arrayformat : true }
          dispatch(downloadAllCertificates(params))
          dispatch(hideModalCustom())
          toast(<SuccessToast message={"Generando paquete..."} />, {
            icon: <ToastSuccess />,
            hideProgressBar: true,
            className: "toast-success",
            autoClose: 15000
          })
        }
        dispatch(showModalCustom({
          icon: <DownloadFileIcon fill='#2FA5BD' width={100} height={100} />,
          message: `¿Estás seguro que deseas descargar ${response.data} certificados?`,
          confirmText: "Si, Aceptar",
          action
        }))
      }
    } catch (error) {
      console.error("💥 | file: certs.js:377 | getAllIdCurrent | error:", error)
    }
  })

export const downloadCertFiles = createAsyncThunk(
  "certs/downloadFiles",
  /**
   * @param {Object} params
   */
  ({
    id,
    folio_cert = null,
    folio_invoice = null,
    folio_rep = null,
    client = null,
    filesSelected,
    setFormModal = null,
    onSuccess = null
  }) => {
    Object.keys(filesSelected).forEach((key) => {
      // only push selected options
      if (filesSelected[key]) {
        api
          .get(`api/certificate/${id}/download/multiple-files`, {
            responseType: "blob",
            params: { download: [filesSelected[key]] }
          })
          .then((res) => {
            if (filesSelected[key] === "invoice_xml") {
              fileDownload(res.data, `Factura-${folio_invoice}.xml`)
            }
            if (filesSelected[key] === "invoice_pdf") {
              fileDownload(res.data, `Factura-${folio_invoice}.pdf`)
            }
            if (filesSelected[key] === "rep_pdf") {
              fileDownload(res.data, `Pago-${folio_rep}.pdf`)
            }
            if (filesSelected[key] === "rep_xml") {
              fileDownload(res.data, `Pago-${folio_rep}.xml`)
            }
            if (filesSelected[key] === "zeros") {
              fileDownload(res.data, `Certificado-0-${folio_cert}.pdf`)
            }
            if (filesSelected[key] === "modified") {
              fileDownload(res.data, `Certificado-M-${folio_cert}.pdf`)
            }
            if (filesSelected[key] === "original") {
              fileDownload(res.data, `Certificado-${folio_cert}.pdf`)
            }
            if (filesSelected[key] === "reference") {
              fileDownload(res.data, `Referencia-pago-${folio_cert}.pdf`)
            }
          })
      }
    })

    // if (setFormModal) {
    setFormModal(false) // hide modal
    // }
    if (onSuccess) {
      onSuccess()
    }
    toast(<SuccessToast message={"Descarga exitosa"} />, {
      icon: <ToastSuccess />,
      hideProgressBar: true,
      className: "toast-success",
      autoClose: 5000
    })
  }
)

export const downloadCertificateModified = createAsyncThunk(
  "certs/downloadModified",
  ({ id, params, filename, onSuccessCert = null }, { dispatch }) => {
    api
      .get(`api/certificate/${id}/modified`, { responseType: "blob", params })
      .then((res) => {
        fileDownload(res.data, filename)
        dispatch(getCertificates())
      })
    if (onSuccessCert) {
      onSuccessCert()
    }
    toast(<SuccessToast message={"Descarga exitosa"} />, {
      icon: <ToastSuccess />,
      hideProgressBar: true,
      className: "toast-success",
      autoClose: 5000
    })
  }
)

export const sendDocumentsByEmail = createAsyncThunk(
  "certs/sentByEmail",
  async ({ id, filesSelected }) => {
    try {
      const response = await api.post(`api/certificate/${id}/sendByEmail`, {
        sendEmail: filesSelected
      })
      return {
        data: response.data
      }
    } catch (err) {
      // console.log('Error:', err.response.data?.message)
      throw new Error(
        err.response.data?.message ?? "No fue posible cancelar la factura"
      )
    }
  }
)

export const saveTaxData = createAsyncThunk(
  "taxdata/certs/save",
  async ({ payload, nextBillingDate, certPayload }, { dispatch }) => {
    // TODO error handler
    const response = await api.post(`api/clients/business_name`, payload)
    if (response.data.status === "success") {
      // retrieve tax data created
      certPayload.razon_social_facturar = response.data.data.id
      // send data to create certificate
      dispatch(saveCertificate({ data: { certPayload }, nextBillingDate }))
    }
  }
)

export const sendAPCertByEmail = createAsyncThunk(
  "commissions/sendAPCertByEmail",
  async ({ id, params }) => {
    try {
      const response = await api.post(
        `api/certificates/personal_accidents/${id}/sendByEmail`,
        {
          ...params
        }
      )
      toast(<SuccessToast message={response.data.message} />, {
        icon: <ToastSuccess />,
        hideProgressBar: true,
        className: "toast-success",
        autoClose: 5000
      })
      return {
        data: response.data,
        params
      }
    } catch (err) {
      toast(<ErrorToast message={"Error al enviar el correo"} />, {
        icon: null,
        hideProgressBar: true,
        className: "toast-danger",
        autoClose: 5000
      })
    }
  }
)

export const getRenewCertificates = createAsyncThunk(
  "certs/renew",
  async (params, { dispatch, getState }) => {
    const state = getState()
    const pages = state.certificate.pages
    const page = params.page ?? 1
    const aux = state.certificate.params
    const { page: pageAux, ...current_params } = aux
    const { page: newPageAux, ...new_params } = params
    try {
      const response = await api.get(`api/dashboard-global/certificates-renew`, { params })
      if (
        Object.keys(pages).length === 0 ||
        !pages.hasOwnProperty(page) ||
        !_.isEqual(current_params, new_params)
      ) {
        return {
          params,
          data: response.data
        }
      } else if (pages.hasOwnProperty(page)) {
        return {
          params,
          data: response.data
        }
      }
    } catch (error) {
    }
  }
)

export const wizardSlice = createSlice({
  name: "cetificate",
  initialState: {
    data: null, // data from backend
    pages: {},
    params: {},
    edit: false,
    resume: {
      counter_issued: 0,
      counter_valid: 0,
      counter_near_expire: 0,
      sum_amount: 0
    },
    autocompleteValues: [],
    lastCertificate: null,
    favoriteSettings: null,
    restrictedCountries: [],
    suggestions: {
      folio: [],
      invoice: [],
      custom: []
    },
    response: [],
    modifyCert: {
      netPremium: 0
    },
    cert: null, // just one certificate, ideal for edit
    certMassive: null,
    certMassiveErrors: [],
    previewUrl: "",
    previewEmployeeUrl: "",
    previewLoading: false,
    certSend: false,
    suggestionsStatus: "idle",
    status: "idle",
    table_status: "idle",
    renew: null,
    sending_email: false
  },
  reducers: {
    cleanCerts: (state) => {
      state.data = []
      state.autocompleteValues = []
      state.lastCertificate = null
      state.favoriteSettings = null
      state.restrictedCountries = []
      state.suggestions = {
        folio: [],
        invoice: [],
        custom: []
      }
      state.response = []
      state.modifyCert = {
        netPremium: 0
      }
      state.cert = null
      // state.previewUrl = ''
      state.certSend = false
    },
    cleanCertSend: (state) => {
      state.response = []
      state.certSend = false
    },
    cleanPreviewUrl: (state) => {
      state.previewUrl = ""
    },
    clearStatus: (state) => {
      state.status = "idle"
    },
    clearCert: (state) => {
      state.cert = null
    },
    clearPage: (state, action) => {
      if (action.payload?.page) state.pages[action.payload?.page] = {}
      else state.pages = {}
    },
    updateCertMassive: (state, action) => {
      const key = action.payload.key

      state.certMassive[key] = action.payload.data
      state.certMassiveErrors = state.certMassiveErrors.filter(
        (item) => item.key !== key
      )
    },
    clearCertMassive: (state) => {
      state.certMassive = null
      state.certMassiveErrors = []
    },
    setEditCert: (state, action) => {
      state.edit = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(saveCertificate.pending, (state) => {
      state.status = "loading"
    })
    builder.addCase(updateCertificate.pending, (state) => {
      state.status = "loading"
    })
    builder.addCase(saveCertificate.fulfilled, (state, action) => {
      state.cert = null
      state.status = "successfull"
      state.response = action.payload.data
      state.pages[1] = {}
    })
    builder.addCase(updateCertificate.fulfilled, (state) => {
      state.status = "successfull"
    })
    builder.addCase(saveCertificate.rejected, (state, action) => {
      state.status = "error"
      state.response = {
        message: action.payload || action.error.message
      }
    })
    builder.addCase(updateCertificate.rejected, (state, action) => {
      state.status = "error"
      state.response = {
        message: action.payload || action.error.message
      }
    })
    builder.addCase(getCertificates.pending, (state, action) => {
      const { page: pageAux, ...current_params } = state.params
      const { page, ...new_params } = action.meta?.arg
      if (_.isEqual(current_params, new_params)) {
        if (!state.pages.hasOwnProperty(page)) state.table_status = "loading"
      } else state.table_status = "loading"
    })
    builder.addCase(getCertificates.fulfilled, (state, action) => {
      state.table_status = "sucessfull"
      const { page: pageAux, ...current_params } = state.params
      if (action?.payload) {
        const { page: newPageAux, ...new_params } = action?.payload?.params
        state.cert = null
        state.data = action.payload?.data
        state.params = action.payload?.params
        const page = action.payload?.params?.page ?? 1
        if (_.isEqual(current_params, new_params)) {
          state.pages[page] = action.payload?.data
        } else {
          state.pages = {}
          state.pages[page] = action.payload?.data
        }
      } else {
        state.cert = null
        state.data = null
        state.params = null
        state.pages = null
      }
    })
    builder.addCase(getCertificates.rejected, (state) => {
      state.table_status = "error"
    })
    builder.addCase(getRenewCertificates.pending, (state, action) => {
      const { page: pageAux, ...current_params } = state.params
      const { page, ...new_params } = action.meta?.arg
      if (_.isEqual(current_params, new_params)) {
        if (!state.pages.hasOwnProperty(page)) state.table_status = "loading"
      } else state.table_status = "loading"
    })
    builder.addCase(getRenewCertificates.fulfilled, (state, action) => {
      state.table_status = "sucessfull"
      const { page: pageAux, ...current_params } = state.params
      if (action?.payload) {
        const { page: newPageAux, ...new_params } = action?.payload?.params
        state.cert = null
        state.data = action.payload?.data
        state.params = action.payload?.params
        const page = action.payload?.params?.page ?? 1
        if (_.isEqual(current_params, new_params)) {
          state.pages[page] = action.payload?.data
        } else {
          state.pages = {}
          state.pages[page] = action.payload?.data
        }
      } else {
        state.cert = null
        state.data = null
        state.params = null
        state.pages = null
      }
    })
    builder.addCase(getRenewCertificates.rejected, (state) => {
      state.table_status = "error"
    })
    builder.addCase(loadCertificatesMassive.pending, (state) => {
      state.status = "loading"
    })
    builder.addCase(loadCertificatesMassive.fulfilled, (state, action) => {
      state.status = "successfull"
      state.certMassive = action.payload.data
    })
    builder.addCase(loadCertificatesMassive.rejected, (state, action) => {
      state.status = "error"
      state.response = {
        message: action.payload || action.error.message
      }
    })
    builder.addCase(saveCertificatesMassive.pending, (state) => {
      state.status = "loading"
    })
    builder.addCase(saveCertificatesMassive.fulfilled, (state) => {
      state.status = "successfull"
    })
    builder.addCase(saveCertificatesMassive.rejected, (state, action) => {
      state.status = "error"
      state.certMassiveErrors = []

      if (action?.payload?.errors) {
        Object.keys(action.payload.errors).map((key) => {
          const [, index, column] = key.split(".")

          state.certMassiveErrors.push({
            key: parseInt(index),
            column,
            message: action.payload.errors[key][0]
          })
        })
      }
    })
    builder.addCase(getPage.fulfilled, (state, action) => {
      const page = action.payload?.params?.page ?? 1
      // console.debug('Page:', page)
      state.pages[page] = action.payload?.data
    })
    builder.addCase(saveRenewCert.fulfilled, (state, action) => {
      state.renew = action.payload
    })
    builder.addCase(getCertificatesSuggestions.fulfilled, (state, action) => {
      state.autocompleteValues = action.payload.data
    })
    builder.addCase(getlastCertificateByUserAndProduct.fulfilled, (state, action) => {
      state.lastCertificate = action.payload.data
    })
    builder.addCase(getSuggestions.pending, (state) => {
      state.suggestionsStatus = "pending"
    })
    builder.addCase(getSuggestions.fulfilled, (state, action) => {
      state.suggestionsStatus = "success"
      state.autocompleteValues = action.payload.data
    })
    builder.addCase(getSuggestions.rejected, (state) => {
      state.suggestionsStatus = "error"
    })
    builder.addCase(getFavoriteSettings.fulfilled, (state, action) => {
      state.favoriteSettings = action.payload.data
    })
    builder.addCase(getRestrictedCountries.fulfilled, (state, action) => {
      state.restrictedCountries = action.payload.data
    })
    builder.addCase(getCertificatesSuggestionsV2.fulfilled, (state, action) => {
      state.suggestions = {
        folio:
          action.payload?.data?.folio_suggestions ?? state.suggestions.folio,
        invoice:
          action.payload?.data?.invoice_suggestions ??
          state.suggestions.invoice,
        custom:
          action.payload?.data?.custom_suggestions ?? state.suggestions.custom,
        rep: action.payload?.data?.rep_suggestions ?? state.suggestions.rep
      }
    })
    builder.addCase(getCertificateById.fulfilled, (state, action) => {
      state.cert = action.payload.data.data
    })
    builder.addCase(verifyCertificateById.fulfilled, (state, action) => {
      state.cert = action.payload.data.data
    })
    builder.addCase(previewCertificate.fulfilled, (state, action) => {
      state.previewLoading = false
      state.previewUrl = action.payload.data
    })
    builder.addCase(previewCertificate.pending, (state) => {
      state.previewLoading = true
    })
    builder.addCase(previewCertificateByEmployee.pending, (state) => {
      state.previewLoading = true
    })
    builder.addCase(previewCertificateByEmployee.fulfilled, (state, action) => {
      state.previewLoading = false
      state.previewEmployeeUrl = action.payload.data
    })
    builder.addCase(sendDocumentsByEmail.fulfilled, (state, action) => {
      state.certSend = true
      state.response = action.payload.data
    })
    builder.addCase(sendDocumentsByEmail.rejected, (state, action) => {
      state.certSend = true
      state.response = {
        status: "error",
        data: [],
        message: action.payload
      }
    })
    builder.addCase(getScheduledCertificates.fulfilled, (state, action) => {
      state.table_status = "sucessfull"
      state.data = action.payload?.data
    })
    builder.addCase(getCertificateResume.fulfilled, (state, action) => {
      state.resume = action.payload?.data
    })
    builder.addCase(sendAPCertByEmail.pending, (state) => {
      state.sending_email = true
    })
    builder.addCase(sendAPCertByEmail.fulfilled, (state) => {
      state.sending_email = false
    })
    builder.addCase(sendAPCertByEmail.rejected, (state) => {
      state.sending_email = false
    })
    builder.addCase(getAllIdCurrent.fulfilled, (state, action) => {
      state.allIdCurrent = action.payload?.data
    })
  }
})

export const {
  cleanCerts,
  cleanCertSend,
  cleanPreviewUrl,
  clearStatus,
  clearCert,
  clearPage,
  updateCertMassive,
  clearCertMassive,
  setEditCert
} = wizardSlice.actions

export default wizardSlice.reducer
